import React from "react";
import { Breadcrumb } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils"

const Breadcrumbs =(props) => {
  if (typeof window !== "undefined") {
    var lastURLSegment = '';
    var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
    if(props.alias !=null) {
      lastURLSegment = props.alias
    }
  }


 const data = useStaticQuery(graphql`
    query BreadcrumbQuery {  
      glstrapi {
        allMenus {
          URL
          Label
          Alias
          Main_Parent {
            URL
            Alias
            Label
          }
          Sub_Parent {
            Label
            URL
            Alias
          }
        }
      }    
    }
  `)


  return(

      <Breadcrumb className="breadcrumb_cnt">
        <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
        {
          data.glstrapi && data.glstrapi.allMenus && data.glstrapi.allMenus.map((node, key) => {

            return(
              <>
                {node.Main_Parent != null && node.URL == lastURLSegment && (
                  node.Main_Parent.URL === "about" ? <li className="breadcrumb-item"><Link>{node.Main_Parent.Label}</Link></li> : <li className="breadcrumb-item"><Link to={`/${node.Main_Parent.URL}`}>{node.Main_Parent.Label}</Link></li>
                )
                  
                }
                {node.Main_Parent != null && node.Sub_Parent != null && node.URL == lastURLSegment &&
                  <li className="breadcrumb-item"><Link to={`/${node.Main_Parent.URL}/${node.Sub_Parent.URL}/`}>{node.Sub_Parent.Label}</Link></li>
                }
                {node.Main_Parent != null && node.URL == lastURLSegment && 
                  <li className="breadcrumb-item active">{props.pageName}</li>
                }
                {(node.Main_Parent === null && node.Sub_Parent === null) && node.URL === lastURLSegment && 
                  <li className="breadcrumb-item active">{props.pageName}</li>
                }
              </>
            )
          })
        }
      </Breadcrumb>

  )
}

export default Breadcrumbs;

