import React from "react";
import HTMLReactParser from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { GetURL } from "../../common/site/functions";
import { Link } from "@StarberryUtils";
import { Col, Row, Container } from 'react-bootstrap';
import './Service.scss';

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import Img from 'gatsby-image';
import { InView } from "react-intersection-observer";


const intro = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.8
        }
    }
}

const contentContainer = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.8
        }
    }
}

const contentItemText = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            delay: 0.4,
            duration: 0.8
        }
    }
}

const contentItemImageLeft = {
    hidden: { opacity: 0, x: 50 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.5
        }
    }
}

const contentItemImageRight = {
    hidden: { opacity: 0, x: -50 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 0.5
        }
    }
}

const MotionContainer = motion(Container)
const MotionRow = motion(Row)
const MotionCol = motion(Col)

const Service = (props) => {
    let length = props.data.length;
    return (
        <section className="service">

            {props.data.length === 1 ?
                <InView {...inViewOptions}>
                    {({ ref, inView }) => (
                        <MotionContainer
                            className="intro"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={intro}
                        >
                            {props.data && props.data.map((module, i) => {
                                let url = ''
                                if (module.Service_CTA_Link) {
                                    url = GetURL(module.Service_CTA_Link._id)
                                }
                                return (
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ScrollAnimation animateIn='fadeInLeft'>
                                                <div className="about-service">
                                                    {props.left_title && i === 0 &&
                                                        <span className="sub-content">
                                                            {props.left_title}
                                                        </span>
                                                    }
                                                    {module.Service_Image &&
                                                        <div className="about-img">
                                                            <picture>
                                                                <source media="(min-width:1200px)" srcSet={module.Service_Image.url} />
                                                                <source media="(min-width:768px)" srcSet={module.Service_Image.url} />
                                                                <img src={module.Service_Image.url} alt={module.Service_Image.alternativeText} />
                                                            </picture>
                                                        </div>
                                                    }
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <div className="col-xl-5 ml-xl-auto">
                                            <ScrollAnimation animateIn='fadeInRight'>
                                                <div className="service-description">
                                                    <h2>{module.Service_Title}</h2>
                                                    {HTMLReactParser(module.Service_Content.replace(/\n/g, '<br />'))}

                                                    {module.Service_CTA_Label && module.Service_CTA_Link &&
                                                        <Link to={`${url}`} className="btn btn-primary">
                                                            {module.Service_CTA_Label}
                                                        </Link>
                                                    }
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                )
                            })}
                        </MotionContainer>
                    )}
                </InView>

                :

                <Container className='service-block-tiles counter'>
                    <div className="row counter-wrapper">
                        {props.data && props.data.map((module, i) => {
                            let url = ''
                            if (module.Service_CTA_Link) {
                                url = GetURL(module.Service_CTA_Link._id)
                            }
                            return (
                                <>
                                    {i % 2 === 0 ?
                                        <InView {...inViewOptions}>
                                            {({ ref, inView }) => (
                                                <MotionCol
                                                    id={i}
                                                    xs={12}
                                                    ref={ref}
                                                    initial="hidden"
                                                    animate={inView ? "visible" : "hidden"}
                                                    variants={contentContainer}
                                                    className="full-width-wrapper col-12"
                                                >
                                                    <div className={`tile-1 ${!module.Service_Image ? "full-w-tile" : ""} ${length === i + 1 ? 'last' : ''}`} key={i}>
                                                        <MotionCol xs={12}
                                                            sm={12}
                                                            lg={12}
                                                            xl={6}
                                                            variants={contentItemImageLeft}
                                                            className={`${!module.Service_Image ? "d-none" : ""}`}
                                                        >
                                                            <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                                <div className="about-service">
                                                                    {props.left_title && i === 0 &&
                                                                        <span className="sub-content">
                                                                            {props.left_title}
                                                                        </span>
                                                                    }
                                                                    {(module.Service_Image) &&
                                                                        <div className="about-img">
                                                                            {/* <picture>
                                                                                <source media="(min-width:1200px)" srcSet={module.Service_Image.url} />
                                                                                <source media="(min-width:768px)" srcSet={module.Service_Image.url} />
                                                                                <img src={module.Service_Image.url} alt={module.Service_Image.alternativeText} />
                                                                            </picture> */}
                                                                            <Img fluid={module.Service_Image.url_sharp.childImageSharp.fluid} alt={module.Service_Image.alternativeText} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </ScrollAnimation>
                                                        </MotionCol>
                                                        <MotionCol xs={12}
                                                            sm={12}
                                                            lg={12}
                                                            xl={6}
                                                            variants={contentItemText}
                                                            className={`ml-xl-auto content-2 ${!module.Service_Image ? "full-width-service" : ""}`}
                                                        >
                                                            <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                                                <div className="service-description content-world">
                                                                    <h2>{module.Service_Title}</h2>
                                                                    {module.Service_Content &&
                                                                        <>
                                                                            {props.class === "Mortgage" ? HTMLReactParser(module.Service_Content) : HTMLReactParser(module.Service_Content.replace(/\n/g, '<br />'))}
                                                                        </>
                                                                    }

                                                                    {module.Service_CTA_Label && module.Service_CTA_Link &&
                                                                        <Link to={`${url}`} className="btn btn-primary">
                                                                            {module.Service_CTA_Label}
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </ScrollAnimation>
                                                        </MotionCol>
                                                    </div>
                                                </MotionCol>
                                            )}
                                        </InView>
                                        :
                                        <InView {...inViewOptions}>
                                            {({ ref, inView }) => (
                                                <MotionCol xs={12}
                                                    id={i}
                                                    ref={ref}
                                                    initial="hidden"
                                                    animate={inView ? "visible" : "hidden"}
                                                    variants={contentContainer}
                                                    className="full-width-wrapper"
                                                >
                                                    <div className={`tile-2 ${!module.Service_Image ? "full-w-tile" : ""} ${length === i + 1 ? 'last' : ''}`} key={i}>
                                                        <MotionCol xs={12}
                                                            sm={12}
                                                            lg={12}
                                                            xl={6}
                                                            variants={contentItemText}
                                                            className={`ml-xl-auto content-2 div-1 ${!module.Service_Image ? "full-width-service" : ""}`}
                                                        >
                                                            <ScrollAnimation animateIn='fadeInRight' animateOnce>
                                                                <div className="service-description">
                                                                    <h2>{module.Service_Title}</h2>
                                                                    {module.Service_Content &&
                                                                        <>
                                                                            {props.class === "Mortgage" ? HTMLReactParser(module.Service_Content) : HTMLReactParser(module.Service_Content.replace(/\n/g, '<br />'))}
                                                                        </>
                                                                    }

                                                                    {module.Service_CTA_Label && module.Service_CTA_Link &&
                                                                        <Link to={`${url}`} className="btn btn-primary">
                                                                            {module.Service_CTA_Label}
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </ScrollAnimation>
                                                        </MotionCol>
                                                        <MotionCol xs={12}
                                                            sm={12}
                                                            lg={12}
                                                            xl={6}
                                                            variants={contentItemImageRight}
                                                            className={`div-2 ${!module.Service_Image ? "d-none" : ""}`}
                                                        >
                                                            <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                                                                <div className="about-service">
                                                                    {props.left_title && i === 0 &&
                                                                        <span className="sub-content">
                                                                            {props.left_title}
                                                                        </span>
                                                                    }
                                                                    {(module.Service_Image) &&
                                                                        <div className="about-img">
                                                                            {/* <picture>
                                                                                <source media="(min-width:1200px)" srcSet={module.Service_Image.url} />
                                                                                <source media="(min-width:768px)" srcSet={module.Service_Image.url} />
                                                                                <img src={module.Service_Image.url} alt={module.Service_Image.alternativeText} />
                                                                            </picture> */}
                                                                            <Img fluid={module.Service_Image.url_sharp.childImageSharp.fluid} alt={module.Service_Image.alternativeText} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </ScrollAnimation>
                                                        </MotionCol>
                                                    </div>
                                                </MotionCol>
                                            )}
                                        </InView>
                                    }
                                </>
                            )
                        })}
                    </div>
                </Container>
            }
        </section >
    )
};

export default Service