import React from "react";
import { Breadcrumb } from 'react-bootstrap';
import { Link } from "@StarberryUtils";

const Breadcrumbs =(props) => {
    if (typeof window !== "undefined") {
        var lastURLSegment = '';
        var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
        if(props.alias !=null) {
            lastURLSegment = props.alias
        }
    }

    var pathname = props.location.pathname.split('/');

    const getSlug = (item_length) => {
        var slug = [];

        pathname && pathname.length > 0 && pathname.filter(item => item !== "").map((item, index) => {
            if(index <= item_length){
                slug.push(item);
            }      
        })
        return slug;
    }

    return(

        <Breadcrumb className={`breadcrumb_cnt ${props.className}`}>
            <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
            {pathname && pathname.length > 0 && pathname.filter(item => item !== "").map((item, index) => {
                if(index < pathname.length - 2){
                    var get_slug = getSlug(index);
                    if(item === "about"){
                    return(
                        <li className="breadcrumb-item"><Link>{item.replace(/-/g,' ')}</Link></li>
                    ) 
                    } else{
                    return(
                        <li className="breadcrumb-item"><Link to={`/${get_slug.join('/')}`}>{item.replace(/-/g,' ')}</Link></li>
                    ) 
                    }
                    
                } else{
                    return(
                    <li className="breadcrumb-item active"><Link>{props.title ? props.title : item.replace(/-/g,' ')}</Link></li>
                    )  
                }                        
            })
            }
        </Breadcrumb>

    )
}

export default Breadcrumbs;

