import React, { useState, useEffect } from "react";
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}


const CustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false);

     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }


    
    return(
        <>
        <strong className="video-btn" onClick={() => { setOpen(true); trackerVideo(props.title);}} onKeyDown={()=> setOpen(true)}>
          <span className="icon-video icon-custom"/>
        </strong>

            {isOpen &&
                <ModalWindow>
                    <ModalVideo channel='custom' isOpen={isOpen} url={`${props.url}?autoplay=1`} onClose={() => setOpen(false)} />
                </ModalWindow>
            }
        </>
    )
}

export default CustomVideo

