import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import './IntroCard.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import BreadcrumbsDetails from "../../Breadcrumbs/Breadcrumbs_Details";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const titleVariants = {
    hidden: {
        opacity: 0,
        y: 40
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.33, 1, 0.68, 1],
            duration: 1
        }
    }
}

function IntroCard(props) {

    return (
        <InView threshold={0.2}>
            {({ ref, inView }) => {
                return (
                    <motion.div className="intro-head-section into-wrapper-card"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl={8} lg={10} xs={11} className="head-con">
                                    <motion.div variants={titleVariants}>
                                        <ScrollAnimation animateIn='fadeInLeft' className="out-team-intro our-team-intro-banner" animateOnce>
                                            {
                                                props.tag === "areaguide" ? (
                                                    <BreadcrumbsDetails className="" location={props.location} pageName={"Meet Our Team"} title={props.title} />
                                                ) : (
                                                    <Breadcrumbs pageName={props.page && props.page.Pagename} />
                                                )
                                            }

                                            {/* {props.page ? <p className="page-title">{props.page.Pagename}</p> : '' } */}

                                            {props.content &&
                                                <>
                                                    {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                                                </>
                                            }

                                            {(props.tag === 'areaguide' && props.title) &&
                                                <div className="buttons area-guide-buttons">
                                                    <Link to="/book-a-valuation" className="btn btn-primary">
                                                        Market Appraisal
                                                    </Link>
                                                    <Link to={`/property/for-sale/in-${props.title.trim().split(" ").join("-").toLowerCase()}`} className="btn btn-primary">
                                                        Property Search
                                                    </Link>
                                                </div>
                                            }
                                        </ScrollAnimation>
                                    </motion.div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.div>
                )
            }}
        </InView>
    )
};

export default IntroCard;