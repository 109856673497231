import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@StarberryUtils"
import ScrollAnimation from 'react-animate-on-scroll';
import './Gallery.scss'
import { Helmet } from "react-helmet";

const Gallery = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
			    siteConfig {
                    Instagram_Link
			    }
            }
        }
    `);
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 5.2,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3.1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
        ],
    }
    return (
        <section className="gallery gallery-bg-fullwidth">
            <Helmet>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
            <div className="slider-wrap">
                <div className="slider px-md-2 px-0">
                    {/* <div className="mobile-wrapper">
                        <div className="follow mb-2">
                            <div className="content">
                                {data.glstrapi.siteConfig.Instagram_Link && <a href={data.glstrapi.siteConfig.Instagram_Link} target="_blank" className="social-link">
                                    <i className="icon-insta">
                                    </i>
                                </a> }
                                <span className="sm-content">be inspired</span>
                                {data.glstrapi.siteConfig.Instagram_Link && <a href={data.glstrapi.siteConfig.Instagram_Link} target="_blank" className="btn">follow us</a>}
                            </div>
                        </div>
                        <div className="w-50 mt-4 px-2">
                            <div class="elfsight-app-dcf63525-3bf2-465f-9dd1-97e9d5a92bf3"></div>
                        </div>
                    </div> */}
                    <ScrollAnimation animateIn='fadeInLeft' animateOnce>
                        <div className="gallery-wrapper">
                            <div className="follow">
                                <div className="content">
                                    {data.glstrapi.siteConfig.Instagram_Link && <a href={data.glstrapi.siteConfig.Instagram_Link} target="_blank" className="social-link">
                                        <i className="icon-insta">
                                        </i>
                                    </a>}
                                    <span className="sm-content">be inspired</span>
                                    <span className="sm-description">
                                        Check us out on Instagram
                                    </span>
                                    {data.glstrapi.siteConfig.Instagram_Link && <a href={data.glstrapi.siteConfig.Instagram_Link} target="_blank" className="btn">follow us</a>}
                                </div>
                            </div>

                            <div className="slider-wrapper">
                                <div class="elfsight-app-dcf63525-3bf2-465f-9dd1-97e9d5a92bf3"></div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>

            </div>

        </section>
    )
};
export default Gallery;